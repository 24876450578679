<template>
  <CommonLayout class="orders-layout">
    <div class="orders">
      <div class="orders__container">
        <div class="orders__header">
          <router-link
            class="button-default button-back orders__back"
            tag="button"
            :to="{ name: 'store' }"
            ><span></span>Назад</router-link
          >
          <h1 class="title">Список заказов</h1>
        </div>
        <Loading v-if="loading" position="center" theme="dark" />
        <div v-else class="orders__list">
          <div class="orders__list-header">
            <div>Номер заказа</div>
            <div>Статус</div>
            <div>Сумма</div>
            <div>Состав заказа</div>
          </div>
          <div class="orders__list-items">
            <div
              v-for="(item, index) in getOrders"
              :key="index"
              class="accordion"
              :class="{ accordion_opened: list[index] }"
            >
              <div class="accordion__header">
                <div>{{ item.id }}</div>
                <div>{{ item.status | orderStatus }}</div>
                <div>{{ item.orderPrice | priceFilter }}</div>
                <div>
                  <div @click="show(index)" class="accordion__open">
                    {{ list[index] ? "Свернуть" : "Развернуть" }}
                    <div class="accordion__open-icon">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 7.07129L8.07107 14.1424L15.1421 7.07129"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="accordion__content"
                v-if="list[index]"
                :key="'list' + index"
              >
                <table class="product-table">
                  <tr>
                    <th>Наименование</th>
                    <th>Количество</th>
                    <th>Стоимость</th>
                  </tr>
                  <tr v-for="(item, index) in item.orderDetails" :key="index">
                    <td>{{ item.prizeName }}</td>
                    <td>{{ item.prizeAmount }}</td>
                    <td>{{ item.prizePrice | priceFilter }} {{ COINS }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- <div class="orders__footer">
            <router-link class="text-history button" :to="{ name: 'cart' }">
              Перейти в корзину
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CommonLayout,
  },
  filters: {
    orderStatus(id) {
      const statusMap = {
        0: "Новый",
        1: "На обработке",
        2: "Выполнен",
      };

      return statusMap[id];
    },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters(["getOrdersStatus", "getOrders"]),
    loading() {
      return this.getOrdersStatus === "loading";
    },
  },
  created() {
    this.ORDERS_FETCH();
  },
  methods: {
    ...mapActions(["ORDERS_FETCH"]),
    show(index) {
      this.$set(this.list, index, !this.list[index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.orders {
  &__container {
    position: relative;
    z-index: 2;
    width: 1230px;
    margin: 0 auto;
  }
  &__header {
    position: relative;
    margin-bottom: 40px;
    font-size: 54px;
    color: #5cffed;
  }
  &__back {
    position: absolute;
    right: 100%;
    top: 16px;
    margin-right: 20px;
    @media (max-width: 1700px) {
      display: none;
    }
  }
  &__title {
    font-weight: normal;
    font-size: 40px;
  }
  &__list {
    // max-width: 811px;
    &-header {
      display: flex;
      padding: 21px 0;
      background-image: url(~@/assets/img/pic2/divider-table.svg);
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-size: 100% auto;
      > div {
        @include editable-text();
        flex: 0 1 auto;
        padding-left: 30px;
        &:nth-child(1) {
          width: percentage(180 / 811);
        }
        &:nth-child(2) {
          width: percentage(206 / 811);
        }
        &:nth-child(3) {
          width: percentage(206 / 811);
        }
        &:nth-child(4) {
          width: percentage(177 / 811);
        }
      }
    }
  }
  &__footer {
    margin-top: 35px;
    text-align: center;
  }
}

.accordion {
  position: relative;
  &:first-child {
    .accordion__header {
      &::before {
        display: block;
      }
    }
  }
  &_opened {
    z-index: 2;
    color: $fontColor;
  }
  &__header {
    position: relative;
    display: flex;
    font-weight: bold;
    font-size: 20px;
    padding: 24px 0;
    background-image: url(~@/assets/img/pic2/divider-table.svg);
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 100% auto;
    > div {
      @include editable-text();
      flex: 0 1 auto;
      padding-left: 30px;
      &:nth-child(1) {
        width: percentage(180 / 811);
      }
      &:nth-child(2) {
        width: percentage(206 / 811);
      }
      &:nth-child(3) {
        width: percentage(206 / 811);
      }
      &:nth-child(4) {
        width: percentage(169 / 811);
      }
    }
  }
  &__open {
    @include clickable();
    text-decoration: underline;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      color: #edeef2;
      svg {
        path {
          stroke: #edeef2;
        }
      }
    }
    &-text {
      flex: 0 0 auto;
    }
    &-icon {
      flex: 0 0 auto;
      position: relative;
      top: 0px;
      display: inline-block;
    }
  }
  &__content {
    // padding: 0 0 10px;
  }
}

.product-table {
  font-size: 16px;
  width: 100%;
  background-color: rgba(0, 255, 254, 0.15);
  background-image: url(~@/assets/img/pic2/divider-table.svg);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  td,
  th {
    padding-right: 20px;
    padding-left: 30px;
    text-align: left;
    &:nth-child(1) {
      width: percentage(475 / 1000);
      padding-left: 30px;
    }
    &:nth-child(2) {
      width: percentage(255 / 1000);
    }
    &:nth-child(3) {
      // width: percentage(200 / 1000);
      padding-right: 30px;
    }
  }
  th {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #ade5d9;
  }
  td {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  tr {
    &:nth-child(even) {
      border: none;
    }
  }
}
</style>
